.App body {
	margin: 10pt;
}

.App-header {
	background-color: #282c34;
	padding: 5pt;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
